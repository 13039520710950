import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { CerrarSesion } from './CerrarSesion';

const Sidebar = () => {
    const [ocultarMostrar, setOcultarMostrar] = useState('hidden');
    return ( 
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
                <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                {/* Toggler */}
                    <button
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() => setOcultarMostrar("bg-white m-2 py-3 px-6")}
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    {/* Brand */}
                    <Link
                        className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                        to="/"
                    >
                        Factura-ágil
                    </Link>
                    {/* User */}
                    <ul className="md:hidden items-center flex flex-wrap list-none">
                        <li className="inline-block relative">
                        {/* <NotificationDropdown /> */}
                        </li>
                        <li className="inline-block relative">
                            <CerrarSesion />
                        {/* <MenuConfiguracion /> */}
                        </li>
                    </ul>
                    {/* Collapse */}
                    <div
                        className={
                        "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                        ocultarMostrar
                        }
                    >
                        {/* Collapse header */}
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <Link
                                        className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                                        to="/"
                                    >
                                        Factura-ágil
                                    </Link>
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() => setOcultarMostrar("hidden")}
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Divider */}
                        <hr className="my-4 md:min-w-full" />
                        {/* Heading */}
                        <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Administración
                        </h6>
                        {/* Navigation */}

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center">
                                <NavLink
                                    className={"text-xs uppercase py-3 px-3 rounded-md font-bold block text-gray-800 hover:text-gray-600" +
                                    (window.location.href.indexOf("/dashboard") !== -1
                                        ? "opacity-75 bg-blue-300"
                                        : "text-gray-400")
                                    }
                                    to="/dashboard"
                                >
                                <i
                                    className="fas fa-tv mr-2 text-sm "
                                ></i>{" "}
                                Dashboard
                                </NavLink>
                            </li>

                            <hr className="my-4 md:min-w-full" />
                            {/* Heading */}
                            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                Comprobantes
                            </h6>
                            <li className="items-center">
                                <NavLink
                                    className={"text-xs uppercase py-3 px-3 rounded-md font-bold block text-gray-800 hover:text-gray-600" +
                                    (window.location.href.indexOf("/emitidas") !== -1
                                        ? "opacity-75 bg-blue-300"
                                        : "text-gray-400")
                                    }
                                    to="/emitidas"
                                >
                                <i
                                    className="far fa-list-alt mr-2 text-sm"
                                ></i>{" "}
                                    Facturas
                                </NavLink>
                            </li>
                            <li className="items-center">
                                <NavLink
                                    className={"text-xs uppercase py-3 px-3 rounded-md font-bold block text-gray-800 hover:text-gray-600" +
                                    (window.location.href.indexOf("/retenciones") !== -1
                                        ? "opacity-75 bg-blue-300"
                                        : "text-gray-400")
                                    }
                                    to="/retenciones"
                                >
                                <i
                                    className="fas fa-file-invoice-dollar mr-2 text-sm"
                                ></i>{" "}
                                    Retenciones
                                </NavLink>
                            </li>
                            <li className="items-center">
                                <NavLink
                                    className={"text-xs uppercase py-3 px-3 rounded-md font-bold block text-gray-800 hover:text-gray-600" +
                                    (window.location.href.indexOf("/notasCredito") !== -1
                                        ? "opacity-75 bg-blue-300"
                                        : "text-gray-400")
                                    }
                                    to="/notasCredito"
                                >
                                <i
                                    className="far fa-sticky-note mr-2 text-sm"
                                ></i>{" "}
                                    Notas de Crédito
                                </NavLink>
                            </li>
                        </ul>
                        {/* Divider */}
                        <hr className="my-4 md:min-w-full" />
                    </div>
                </div>
            </nav>
        </>
     );
}
 
export default Sidebar;